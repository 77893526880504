var render = function () {
  var _vm$flightData, _vm$flightData2, _vm$flightData$data$, _vm$dataForm;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-reservation-modify-add-insurance-services",
      "title": _vm.isEdit ? _vm.$t('reservation[\'changeInsuranceItemTitle\']') : _vm.$t('reservation[\'addInsuranceItemTitle\']'),
      "title-class": "text-airline font-medium-4 font-weight-bolder mb-0",
      "body-class": "px-75",
      "footer-class": "px-50",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "show": _vm.handleShowModal,
      "hide": _vm.handleHideModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill",
          attrs: {
            "size": "md",
            "variant": "outline-secondary px-1 px-sm-2"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), !_vm.isEdit ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-1 px-sm-2 mr-md-2",
          attrs: {
            "variant": "info",
            "disabled": !_vm.isConfirm || !_vm.dataForm || _vm.dataForm && !_vm.dataForm.insuranceItem,
            "pill": ""
          },
          on: {
            "click": _vm.handleAddDraftInsurances
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'addInsurance\']')) + " ")])]) : _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-1 px-sm-2 mr-md-2",
          attrs: {
            "variant": "info",
            "disabled": !_vm.isConfirm || !_vm.dataForm || _vm.dataForm && !_vm.dataForm.insuranceItem,
            "pill": ""
          },
          on: {
            "click": _vm.handleUpdateDraftInsurances
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'confirmChange\']')) + " ")])])];
      }
    }])
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.isLoading,
      "spinner-variant": 'info'
    }
  }, [_c('b-card', {
    staticClass: "border mb-2",
    attrs: {
      "body-class": "p-75"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-1 mb-lg-0",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_vm.bookingData ? _c('BCard', {
    staticClass: "border-info h-100",
    attrs: {
      "header-bg-variant": "light-info",
      "header-class": "fw-700 p-50 px-lg-1 mb-50",
      "body-class": "px-50 px-sm-1 mt-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "my-25"
        }, [_c('span', {
          staticClass: "pr-25 text-airline"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'bookingInfos\']')) + " ")])])];
      },
      proxy: true
    }], null, false, 4093996017)
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'itinerary\']')) + ": "), _c('span', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(" " + _vm._s(!_vm.isEmpty((_vm$flightData = _vm.flightData) === null || _vm$flightData === void 0 ? void 0 : _vm$flightData.data) ? _vm.resolveInsuranceItinerariesName(_vm.flightData.data.map(function (bk) {
    return bk.itineraries;
  }).flat(), 'tripMulti') : _vm.resolveInsuranceItinerariesName(_vm.bookingData.itineraries, 'tripOne')) + " ")])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'First departure time\']')) + ": ")]), _c('span', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(" " + _vm._s(!_vm.isEmpty((_vm$flightData2 = _vm.flightData) === null || _vm$flightData2 === void 0 ? void 0 : _vm$flightData2.data) ? _vm.convertISODateTime((_vm$flightData$data$ = _vm.flightData.data[0].itineraries[0]) === null || _vm$flightData$data$ === void 0 ? void 0 : _vm$flightData$data$.departureDate).dateTime : _vm.convertISODateTime(_vm.bookingData.itineraries[0][0].departure.at, _vm.bookingData.itineraries[0][0].departure.timeZone).dateTime) + " ")])])]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [(_vm$dataForm = _vm.dataForm) !== null && _vm$dataForm !== void 0 && _vm$dataForm.arrSelectPax ? _c('BCard', {
    staticClass: "border-info h-100",
    attrs: {
      "header-bg-variant": "light-info",
      "header-class": "fw-700 p-50 px-lg-1 mb-50",
      "body-class": "px-50 px-sm-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mr-25"
        }, [_c('span', {
          staticClass: "pr-25 text-airline"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.specialServices.passengers.title')) + " ")])]), _c('div', {
          staticClass: "my-25 mr-sm-2"
        }, [_c('b-form-checkbox', {
          staticClass: "text-airline fw-700 font-medium-1 custom-control-info",
          attrs: {
            "id": "CHECK_ALL",
            "disabled": _vm.isEdit,
            "checked": _vm.isCheckedAll(_vm.dataForm.arrSelectPax)
          },
          on: {
            "change": function change($event) {
              return _vm.toggleSelectAll(_vm.dataForm.arrSelectPax);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.isCheckedAll(_vm.dataForm.arrSelectPax) ? _vm.$t('reservation.unselectAll') : _vm.$t('reservation.selectAll')) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 3203494103)
  }, [!_vm.isEmpty(_vm.paxAvailToAddDraftInsurance) ? _c('div', _vm._l(_vm.paxAvailToAddDraftInsurance, function (pax, indexPax) {
    return _c('div', {
      key: indexPax,
      staticClass: "d-flex my-50 my-lg-75"
    }, [_c('b-form-checkbox', {
      staticClass: "custom-control-info",
      attrs: {
        "id": "pax-select_".concat(indexPax + 1),
        "disabled": _vm.isEdit,
        "checked": _vm.isCheckItem(_vm.dataForm.arrSelectPax, pax)
      },
      on: {
        "change": function change($event) {
          return _vm.handleChooseItem(_vm.dataForm.arrSelectPax, pax);
        }
      }
    }, [_c('span', {
      class: "fw-700 pl-md-50 ".concat(_vm.isCheckItem(_vm.dataForm.arrSelectPax, pax) ? 'text-info' : '')
    }, [_vm._v(_vm._s(pax.lastName) + " " + _vm._s(_vm.resolveFirstNamePaxRemoveTitle(pax)))])])], 1);
  }), 0) : _vm._e()]) : _vm._e()], 1)], 1)], 1), _vm.dataForm ? _c('div', [!_vm.isEmpty(_vm.dataListInsurances) ? _c('InsuranceListItem', {
    attrs: {
      "data-list-insurances": _vm.dataListInsurances,
      "selected": _vm.dataForm.insuranceItem,
      "data-insurance-draft": _vm.dataInsuranceDraft
    },
    on: {
      "handleSelect": _vm.handleSelectItem
    }
  }) : _vm.isErrGetListService === 1 ? _c('div', {
    staticClass: "my-5 d-flex-center flex-column"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('reservation[\'Retrieving information about insurance packages\']')) + " ...")]), _c('BSpinner', {
    attrs: {
      "variant": "info"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.isErrGetListService === 2 ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-alert', {
    staticClass: "px-1 py-25 text-center fw-700",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Error retrieving insurance package information, please contact booker to check again\']')) + " ")])], 1) : _vm._e(), _vm.dataForm && !_vm.isEmpty(_vm.dataListInsurances) ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-alert', {
    staticClass: "px-1 py-25 my-1 text-center fw-700",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Note: International Travel Insurance products apply to ages from 01 day old to 75 years old\']')) + " ")])], 1) : _vm._e(), _c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }