<template>
  <b-modal
    id="modal-reservation-modify-add-insurance-services"
    :title="isEdit ? $t('reservation[\'changeInsuranceItemTitle\']') : $t('reservation[\'addInsuranceItemTitle\']')"
    title-class="text-airline font-medium-4 font-weight-bolder mb-0"
    body-class="px-75"
    footer-class="px-50"
    centered
    no-close-on-backdrop
    size="xl"
    @show="handleShowModal"
    @hide="handleHideModal"
  >
    <template #modal-footer="{ close }">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary px-1 px-sm-2"
        class="center rounded-pill"
        @click="close()"
      >
        {{ $t('reservation.back') }}
      </b-button>
      <b-button
        v-if="!isEdit"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="info"
        class="px-1 px-sm-2 mr-md-2"
        :disabled="!isConfirm || !dataForm || (dataForm && !dataForm.insuranceItem)"
        pill
        @click="handleAddDraftInsurances"
      >
        <span class="align-middle"> {{ $t('reservation[\'addInsurance\']') }} </span>
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="info"
        class="px-1 px-sm-2 mr-md-2"
        :disabled="!isConfirm || !dataForm || (dataForm && !dataForm.insuranceItem)"
        pill
        @click="handleUpdateDraftInsurances"
      >
        <span class="align-middle"> {{ $t('reservation[\'confirmChange\']') }} </span>
      </b-button>
    </template>

    <IAmOverlay
      :loading="isLoading"
      :spinner-variant="'info'"
    >
      <b-card
        body-class="p-75"
        class="border mb-2"
      >
        <b-row>
          <!-- ANCHOR  Chọn hành khách -->
          <b-col
            cols="12"
            lg="6"
            class="mb-1 mb-lg-0"
          >
            <BCard
              v-if="bookingData"
              header-bg-variant="light-info"
              header-class="fw-700 p-50 px-lg-1 mb-50"
              body-class="px-50 px-sm-1 mt-1"
              class="border-info h-100"
            >
              <template #header>
                <div class="my-25">
                  <span class="pr-25 text-airline">
                    {{ $t('reservation[\'bookingInfos\']') }}
                  </span>
                </div>
              </template>

              <!-- <p>
                Mã đặt chỗ: <span class="fw-700 text-airline">
                  {{
                    !isEmpty(flightData?.data)
                      ? flightData.data.map(bk => bk.code).join(' | ')
                      : bookingData.bookingCode
                  }}
                </span>
              </p> -->
              <p>
                {{ $t('reservation[\'itinerary\']') }}: <span class="fw-700 text-airline">
                  {{
                    !isEmpty(flightData?.data)
                      ? resolveInsuranceItinerariesName(flightData.data.map(bk => bk.itineraries).flat(), 'tripMulti')
                      :resolveInsuranceItinerariesName(bookingData.itineraries, 'tripOne')
                  }}
                </span>
              </p>
              <div>
                <span>{{ $t('reservation[\'First departure time\']') }}: </span>
                <span class="fw-700 text-airline">
                  {{
                    !isEmpty(flightData?.data)
                      ? convertISODateTime(flightData.data[0].itineraries[0]?.departureDate).dateTime
                      : convertISODateTime(bookingData.itineraries[0][0].departure.at, bookingData.itineraries[0][0].departure.timeZone).dateTime
                  }}
                </span>
              </div>
            </BCard>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <BCard
              v-if="dataForm?.arrSelectPax"
              header-bg-variant="light-info"
              header-class="fw-700 p-50 px-lg-1 mb-50"
              body-class="px-50 px-sm-1"
              class="border-info h-100"
            >
              <template #header>
                <div class="mr-25">
                  <span class="pr-25 text-airline">
                    {{ $t('reservation.specialServices.passengers.title') }}
                  </span>
                </div>
                <div class="my-25 mr-sm-2">
                  <b-form-checkbox
                    id="CHECK_ALL"
                    class="text-airline fw-700 font-medium-1 custom-control-info"
                    :disabled="isEdit"
                    :checked="isCheckedAll(dataForm.arrSelectPax)"
                    @change="toggleSelectAll(dataForm.arrSelectPax)"
                  >
                    {{ isCheckedAll(dataForm.arrSelectPax) ? $t('reservation.unselectAll') : $t('reservation.selectAll') }}
                  </b-form-checkbox>
                </div>
              </template>

              <div v-if="!isEmpty(paxAvailToAddDraftInsurance)">
                <div
                  v-for="(pax, indexPax) of paxAvailToAddDraftInsurance"
                  :key="indexPax"
                  class="d-flex my-50 my-lg-75"
                >
                  <b-form-checkbox
                    :id="`pax-select_${indexPax + 1}`"
                    class="custom-control-info"
                    :disabled="isEdit"
                    :checked="isCheckItem(dataForm.arrSelectPax, pax)"
                    @change="handleChooseItem(dataForm.arrSelectPax, pax)"
                  >
                    <span :class="`fw-700 pl-md-50 ${isCheckItem(dataForm.arrSelectPax, pax) ? 'text-info' : ''}`">{{ pax.lastName }} {{ resolveFirstNamePaxRemoveTitle(pax) }}</span>
                  </b-form-checkbox>
                </div>
              </div>
            </BCard>
          </b-col>
        </b-row>
      </b-card>

      <div v-if="dataForm">
        <InsuranceListItem
          v-if="!isEmpty(dataListInsurances)"
          :data-list-insurances="dataListInsurances"
          :selected="dataForm.insuranceItem"
          :data-insurance-draft="dataInsuranceDraft"
          @handleSelect="handleSelectItem"
        />

        <div
          v-else-if="isErrGetListService === 1"
          class="my-5 d-flex-center flex-column"
        >
          <p>{{ $t('reservation[\'Retrieving information about insurance packages\']') }} ...</p>
          <BSpinner variant="info" />
        </div>
      </div>

      <div
        v-if="isErrGetListService === 2"
        class="d-flex-center"
      >
        <b-alert
          show
          variant="danger"
          class="px-1 py-25 text-center fw-700"
        >
          {{ $t('reservation[\'Error retrieving insurance package information, please contact booker to check again\']') }}
        </b-alert>
      </div>

      <div
        v-if="dataForm && !isEmpty(dataListInsurances)"
        class="d-flex-center"
      >
        <b-alert
          show
          variant="danger"
          class="px-1 py-25 my-1 text-center fw-700"
        >
          {{ $t('reservation[\'Note: International Travel Insurance products apply to ages from 01 day old to 75 years old\']') }}
        </b-alert>
      </div>

      <IAmConfirm
        :is-confirm.sync="isConfirm"
      />
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BCard,
  BFormCheckbox,
  BRow,
  BCol,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import {
  ref, toRefs, watch, computed,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { cloneDeep, isEmpty, isEqual } from 'lodash'

import {
  convertShortTrip,
  convertISODateTime,
  formatCurrency,
} from '@/@core/utils/filter'

import {
  trimInput,
} from '@core/comp-functions/forms/formatter-input'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BFormCheckbox,
    BRow,
    BCol,
    BAlert,
    BSpinner,

    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmConfirm: () => import('@/components/IAmConfirm.vue'),
    InsuranceListItem: () => import('./InsuranceListItem.vue'),
  },
  directives: {
    Ripple,
  },
  props: {
    bookingData: {
      type: Object,
      required: true,
    },
    paxAvailToAddDraftInsurance: {
      type: Array,
      default: () => [],
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
    dataInsuranceDraft: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const { toastError, toastWarning } = useToast()
    const {
      bulkCreateInsuranceDraft,
      updateInsuranceDraft,
      getInsurancePlans,
      resolveFirstNamePaxRemoveTitle,
      resolveInsuranceItinerariesName,
      getFlightByMultiPNRInsurances,
      insuranceIdPaxGenerate,
    } = useReservationHandle()
    const {
      bookingData,
      paxAvailToAddDraftInsurance,
      isEdit,
      dataInsuranceDraft,
    } = toRefs(props)

    const blankDataInsurance = {
      arrSelectPax: [],
      insuranceItem: null,
    }

    const dataForm = ref(null)
    const isLoading = ref(false)
    const isConfirm = ref(false)
    const isErrGetListService = ref(1)
    const dataListInsurances = ref([])
    const JSONTrips = ref('')
    const flightData = computed(() => getFlightByMultiPNRInsurances(bookingData.value))

    const resetInsuranceReqData = () => {
      dataForm.value = cloneDeep(blankDataInsurance)
      isConfirm.value = false
    }

    // NEW: chưa có gói => call api => lưu data list, chỉ reset khi thay đổi hành trình oldIti và new Iti
    function fetchInsurancePlans() {
      isErrGetListService.value = 1
      const { isMultipleCodes, allCodes, data } = flightData.value

      try {
        const payloadGetList = {
          type: 'INSURANCE',
          itineraries: data?.map(bk => bk.itineraries).flat(),
        }

        getInsurancePlans(payloadGetList)
          .then(res => {
            if (!isEmpty(res)) {
              const plans = res[0]?.plans
              if (isEmpty(plans)) {
                isErrGetListService.value = 2
                return
              }
              dataListInsurances.value = [...plans]
              JSONTrips.value = JSON.stringify(data?.map(bk => bk.itineraries).flat())
            } else {
              isErrGetListService.value = 2
              toastWarning({ title: 'messagesList.error', content: 'reservation[\'Error retrieving insurance package information, please contact booker to check again\']' })
            }
          })
          .catch(err => {
            isErrGetListService.value = 2
            console.error({ err })
          })
          .finally(() => {
            isLoading.value = false
          })
      } catch (error) {
        console.error({ error })
      }
    }

    function handleShowModal() {
      resetInsuranceReqData()
      isErrGetListService.value = 1

      if (isEdit.value) {
        dataForm.value.arrSelectPax = paxAvailToAddDraftInsurance.value
      }

      if (isEmpty(dataListInsurances.value)) {
        fetchInsurancePlans()
      } else if (!isEmpty(JSONTrips.value)) {
        const { isMultipleCodes, data } = flightData.value
        if (JSONTrips.value !== JSON.stringify(data?.map(bk => bk.itineraries).flat())) {
          dataListInsurances.value = []
          JSONTrips.value = ''
          fetchInsurancePlans()
        }
      }
    }

    function handleHideModal() {
      emit('update:pax-avail-to-add-draft-insurance', [])
      emit('update:data-insurance-draft', null)
      emit('update:is-edit', false)
    }

    // API Add Draft
    function handleAddDraftInsurances() {
      const { isMultipleCodes, allCodes, data } = flightData.value

      if (isEmpty(dataForm.value.arrSelectPax)) {
        toastWarning({ title: 'reservation[\'Passengers have not been selected yet!\']', content: 'reservation[\'Please select at least one passenger!\']' })
        return
      }

      const resolveMultiCodesByPax = pax => {
        const codes = allCodes.filter(code => code.paxLists.some(px => JSON.stringify(insuranceIdPaxGenerate(px)) === JSON.stringify(insuranceIdPaxGenerate(pax))))
        return codes
      }

      this.$bvModal.show('modal-api-loading')

      const payload = {
        insurances: dataForm.value.arrSelectPax.map(pax => ({
          type: 'INSURANCE',
          passengerId: insuranceIdPaxGenerate(pax),
          passengerName: `${pax.lastName} ${resolveFirstNamePaxRemoveTitle(pax)}`,
          bookingCode: isMultipleCodes ? resolveMultiCodesByPax(pax).map(bk => bk.code).join(' | ') : data.map(bk => bk.code).join(' | '),
          bookingSource: isMultipleCodes ? resolveMultiCodesByPax(pax).map(bk => bk.itineraries[0].source).join(' | ') : data.map(bk => bk.itineraries[0].source).join(' | '),
          planId: dataForm.value.insuranceItem.id,
          planName: dataForm.value.insuranceItem.name,
          planDetails: dataForm.value.insuranceItem.details,
          coverPrice: dataForm.value.insuranceItem.coverPrice,
          currency: dataForm.value.insuranceItem.currency,
          coverPriceCurrency: dataForm.value.insuranceItem.coverPriceCurrency,
          price: dataForm.value.insuranceItem.price,
          totalPrice: dataForm.value.insuranceItem.totalPrice,
          priceCommission: dataForm.value.insuranceItem.priceCommission,
          priceDiscountPercent: dataForm.value.insuranceItem.priceDiscountPercent,
          itineraries: data?.map(bk => bk.itineraries).flat(),
        })),
      }

      bulkCreateInsuranceDraft(payload)
        .then(res => {
          // console.log('resCreateDraft:: ', { res })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-reservation-modify-add-insurance-services')
        })
    }

    // API Update Draft
    function handleUpdateDraftInsurances() {
      this.$bvModal.show('modal-api-loading')

      const payload = {
        id: dataInsuranceDraft.value.id,
        planId: dataForm.value.insuranceItem.id,
        planName: dataForm.value.insuranceItem.name,
        planDetails: dataForm.value.insuranceItem.details,
        bookingCode: dataInsuranceDraft.value.bookingCode,
        coverPrice: dataForm.value.insuranceItem.coverPrice,
        price: dataForm.value.insuranceItem.price,
        totalPrice: dataForm.value.insuranceItem.totalPrice,
        priceCommission: dataForm.value.insuranceItem.priceCommission,
        priceDiscountPercent: dataForm.value.insuranceItem.priceDiscountPercent,
        currency: dataForm.value.insuranceItem.currency,
        coverPriceCurrency: dataForm.value.insuranceItem.coverPriceCurrency,
      }

      updateInsuranceDraft(payload)
        .then(res => {
          // console.log('resCreateDraft:: ', { res })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-reservation-modify-add-insurance-services')
        })
    }

    const handleSelectItem = item => {
      dataForm.value.insuranceItem = item
    }

    // ================= checkBoxAll
    function allPaxChecked(arrPax) {
      const listPax = paxAvailToAddDraftInsurance.value
      const a = (arrPax.length === listPax.length)
      const b = arrPax.every(pax => listPax.some(p => p.paxId === pax.paxId))
      return { listPax, checkAll: a && b }
    }
    function isCheckedAll(arrPax) {
      if (isEmpty(arrPax)) return false
      const { checkAll } = allPaxChecked(arrPax)
      return checkAll
    }
    function toggleSelectAll(arrPax) {
      const { listPax, checkAll } = allPaxChecked(arrPax)
      if (checkAll) {
        arrPax.splice(0, arrPax.length)
      } else {
        const result = listPax.filter(pax => !arrPax.some(p => p.paxId === pax.paxId))
        arrPax.push(...result)
      }
    }

    // ================= checkBoxOne
    function handleChooseItem(arrPax, pax) {
      const index = arrPax.findIndex(p => p.paxId === pax.paxId)
      // eslint-disable-next-line no-unused-expressions
      index >= 0 ? arrPax.splice(index, 1) : arrPax.push(pax)
    }

    function isCheckItem(arrPax, pax) {
      return arrPax.some(p => p.paxId === pax.paxId)
    }

    return {
      isErrGetListService,
      convertISODateTime,
      convertShortTrip,
      isLoading,
      isConfirm,
      isEmpty,
      formatCurrency,
      trimInput,
      dataListInsurances,

      handleShowModal,
      handleHideModal,

      handleSelectItem,
      dataForm,
      handleAddDraftInsurances,
      handleUpdateDraftInsurances,

      toggleSelectAll,
      isCheckedAll,

      handleChooseItem,
      isCheckItem,
      resolveFirstNamePaxRemoveTitle,

      resolveInsuranceItinerariesName,
      flightData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>

</style>
